<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pengeluaran</b></div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push({ name: 'pharmacy-pharmacy-expenses-add' })"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :items="items"
              @btnDeleteOnClick="btnDeleteOnClick"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Card from "@/view/content/Card.vue";
import Table from "@/component/pharmacy/pharmacy-expenses/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  
  components: {
    Card,
    Table,
  },

  data() {
    return {
      // Table Items
      items: [],
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      users: [],
      creater_name: "",
      manipulateBtn: false,
    };
  },

  methods: {

    async list() {
      
      this.items = await module.list("pharmacy-expenses");
    },

    async getByPeriode() {
      // set default filter
      this.creater_name = ""

      // config start date
      // console.log("start", this.start_date)
      // let someDate = new Date(this.start_date)
      // someDate.setDate(someDate.getDate() - 1)
      // let startDate = someDate.toISOString().substr(0, 10)
      // console.log("start - 1", startDate)

      // config end date
      console.log("start", this.end_date);
      let oneDate = new Date(this.end_date);
      oneDate.setDate(oneDate.getDate() + 1);
      let endDate = oneDate.toISOString().substr(0, 10);
      console.log("end + 1", endDate);

      this.items = await module.get(
        "pharmacy-expenses/get-by-periode/" + this.start_date + "/" + endDate
      );
      // If Data Not Found
    },

    async getByCreater() {
      // set default filter
      this.start_date = ""
      this.end_date = ""

      this.items = await module.get(
        "pharmacy-expenses/get-by-creater-name/" + this.creater_name
      );
      // If Data Not Found
      if (this.creater_name == "" || this.creater_name == null) {
        this.list();
      }
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("pharmacy-expenses/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        

        if (access_right[a] == "1404") {
          this.manipulateBtn = true
        }

      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "/pharmacy/dashboard" },
      { title: "Pengeluaran" },
    ]);
    // Get Data
    this.list();
    this.setActiveMenu()
  },
};
</script>

<style>
</style>